import { FC, useState, useMemo, memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/legacy/image';
import {
  ROUTE_BUILD,
  ROUTE_BUILD_PC,
  ROUTE_CART,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_NORTHERN_AMERICA,
} from '@constants';
import { IconTrash } from '@nzxt/react-icons';
import usePrice from '@framework/use-price';
import { getRegion } from '@framework/api/utils/maxify';
// import useUpdateItem from '@framework/cart/use-update-item';
import useRemoveItem from '@framework/cart/use-remove-item';
import type { ShopifyLineEdges } from '@framework/api/cart';
import useUiStore, { getSetCartStockNotice } from '@stores/use-ui-store';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import slugify from '@utils/slugify';
import hasDiscountPrice from '@utils/has-discount-price';
import formatPartLabel from '@utils/format-part-label';
import ButtonLink from '@components/ButtonLink';
import ExtendCartOffer from '@components/Extend/ExtendCartOffer';
import CartItemEsd from './CartItemEsd';
import * as styles from './styles';

type Props = {
  build: ShopifyLineEdges[];
  isRefurbished?: boolean;
};

type SlaItem = {
  id: string;
  name: string;
  quantity: number;
  image?: string;
  slaDate?: string;
};

const CartBuildItem: FC<Props> = ({ build }) => {
  const { locale, pathname } = useRouter();
  const region = getRegion(locale);
  const isCartPage = pathname.includes(ROUTE_CART);

  const buildId =
    build
      .find(i => i.node.attributes.find(a => a.key === '_build_number'))
      ?.node.attributes.find(a => a.key === '_build_number').value ?? '';

  // TODO - commenting out until cart quantity updates are done
  // const max = useMemo(() => {
  //   const availables = build.map(i => i.node.merchandise?.quantityAvailable);
  //   const min = Math.min(...availables);

  //   return min;
  // }, [build]);

  const { price } = usePrice({
    amount: parseFloat(
      build
        .reduce(
          (previousValue, currentValue) =>
            previousValue +
              parseFloat(
                currentValue.node.merchandise?.compareAtPriceV2?.amount ===
                  '0.0'
                  ? currentValue.node.merchandise?.priceV2?.amount
                  : currentValue.node.merchandise?.compareAtPriceV2?.amount
              ) *
                currentValue.node.quantity || 1,
          0
        )
        .toString()
    ),
    currencyCode:
      build[0]?.node.merchandise?.compareAtPriceV2?.currencyCode ?? 'USD',
  });

  const { price: discountPrice } = usePrice({
    amount: parseFloat(
      build
        .reduce(
          (previousValue, currentValue) =>
            previousValue +
              (parseFloat(currentValue.node.merchandise?.priceV2?.amount) -
                parseFloat(
                  currentValue.node.discountAllocations[0]?.discountedAmount
                    ?.amount ?? '0'
                ) /
                  currentValue.node.quantity) *
                currentValue.node.quantity || 1,
          0
        )
        .toString()
    ),
    currencyCode: build[0]?.node.merchandise?.priceV2?.currencyCode ?? 'USD',
  });

  const sanitizedDiscount = discountPrice?.replace(/\D/g, '') || '0';
  const sanitizedPrice = price?.replace(/\D/g, '') || '0';

  const showDiscountPrice =
    hasDiscountPrice(
      build[0]?.node.merchandise?.compareAtPriceV2?.amount,
      build[0]?.node.merchandise?.priceV2?.amount
    ) || parseFloat(sanitizedDiscount) < parseFloat(sanitizedPrice);

  // TODO - commenting out until cart quantity updates are done
  // const updateItem = useUpdateItem();
  const removeItem = useRemoveItem();

  const [removing, setRemoving] = useState(false);
  // TODO - commenting out until cart quantity updates are done
  // const [loading, setLoading] = useState(false);

  // TODO - cheesing this value until cart quantity updates are done
  const loading = false;

  // const updateQuantity = async (val: number): Promise<void> => {
  //   setLoading(true);
  //   try {
  //     const id = build.map(i => i.node.id);

  //     await updateItem({ id, quantity: val });
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };

  const handleRemove = async (): Promise<void> => {
    setRemoving(true);

    try {
      const id = build.map(i => i.node.id);

      await removeItem({ id });
    } catch (error) {
      setRemoving(false);
    }
  };

  const getProductAndVariantName = (item: ShopifyLineEdges): string | null => {
    let variantName = item?.node?.merchandise?.title ?? null;

    // remove obvious SKUs from variant names
    if (typeof variantName === 'string' && variantName.split('-').length >= 3) {
      variantName = '';
    }

    const productName = item?.node?.merchandise?.product?.title ?? null;

    return `${productName}${variantName && ` ${variantName}`}`;
  };

  const EU_PRODUCT_MAP = {
    Cases: 'Gehäuse',
    Cooling: 'Kühlung',
  };

  const getSocketItemByType = (
    productType: string[]
  ):
    | { id: string; name: string; quantity: number; image?: string }[]
    | null => {
    let socket;

    if (Array.isArray(productType) && productType?.length > 0) {
      const hasRegionalMatch = productType?.filter(
        type => EU_PRODUCT_MAP[type]
      );

      let arrayItem = build.filter(i =>
        productType.find(
          itemType =>
            itemType.toLowerCase() ===
            i?.node?.merchandise?.product?.productType.toLowerCase()
        )
      );

      if (region === MAXIFY_REGION_EUROPE && hasRegionalMatch?.length > 0) {
        arrayItem = build.filter(i =>
          productType.find(
            itemType =>
              EU_PRODUCT_MAP[itemType]?.toLowerCase() ===
              i?.node?.merchandise?.product?.productType.toLowerCase()
          )
        );
      }
      if (
        region === MAXIFY_REGION_EUROPE &&
        hasRegionalMatch &&
        (!arrayItem || arrayItem.length === 0)
      ) {
        arrayItem = build.filter(i =>
          productType.find(
            itemType =>
              itemType.toLowerCase() ===
              i?.node?.merchandise?.product?.productType.toLowerCase()
          )
        );
      }

      socket = arrayItem.map(i => ({
        id: i?.node?.id,
        name: getProductAndVariantName(i),
        quantity: i?.node?.quantity ?? null,
        image: i?.node?.merchandise?.image?.url ?? null,
        slaDate: i?.node?.slaDate ?? null,
      }));
    }

    return socket;
  };

  const getSocketItemVariantName = (productType: string): string | null => {
    let prodType = productType;

    if (region === MAXIFY_REGION_EUROPE && EU_PRODUCT_MAP[productType]) {
      prodType = EU_PRODUCT_MAP[productType];
    }

    let item = build.find(
      i =>
        i?.node?.merchandise?.product?.productType.toLowerCase() ===
          prodType.toLowerCase() ?? null
    );

    if (
      region === MAXIFY_REGION_EUROPE &&
      EU_PRODUCT_MAP[productType] &&
      !item
    ) {
      item = build.find(
        i =>
          i?.node?.merchandise?.product?.productType.toLowerCase() ===
            productType.toLowerCase() ?? null
      );
    }

    return getProductAndVariantName(item);
  };

  const parts = useMemo(
    () => ({
      cpuCooler: getSocketItemByType(['Cooling', 'Liquid', 'Coolers']),
      services: getSocketItemByType(['Services']),
      software: getSocketItemByType(['Softwares']),
      case: getSocketItemByType(['Cases']),
      motherboard: getSocketItemByType(['Motherboards']),
      cpu: getSocketItemByType(['CPUs']),
      ram: getSocketItemByType(['RAM']),
      gpu: getSocketItemByType(['GPUs']),
      powerSupply: getSocketItemByType(['PSUs']),
      ssd: getSocketItemByType(['Storage']),
      extras: getSocketItemByType(['Extras', 'Keyboard Extra']),
      rgbs: getSocketItemByType(['RGBs']),
      monitors: getSocketItemByType(['Monitors']),
      peripherals: getSocketItemByType(['Peripherals', 'Mouse', 'Keyboard']),
      caseFans: getSocketItemByType(['Air']),
      'protection Plan': getSocketItemByType(['Extend Service Contract']),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [build, getSocketItemByType]
  );

  const filteredParts = useMemo(() => {
    const newParts = {};

    if (parts) {
      // eslint-disable-next-line no-return-assign
      Object.keys(parts).forEach(k => {
        if (Array.isArray(parts[k])) {
          const newSection = parts[k].filter(n => n.id);

          if (newSection.length > 0) {
            newParts[k] = newSection;
          }
        } else {
          newParts[k] = parts[k];
        }
      });
    }

    return newParts;
  }, [parts]);

  const serviceItem = useMemo(() => parts?.services, [parts]);
  const rawSlaItem =
    Array.isArray(serviceItem) && serviceItem.length > 0
      ? serviceItem[0]
      : serviceItem;
  const slaItem = rawSlaItem as SlaItem;

  const caseImage = useMemo(() => {
    const caseItem = getSocketItemByType(['Cases']);
    const image = caseItem[0]?.image ?? null;

    return image;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build, getSocketItemByType]);

  const buildName =
    `${
      getSocketItemVariantName('Cases')
        ? `${getSocketItemVariantName('Cases')} Build`
        : null
    }` || 'Name';

  const hasBadBuildName = buildName === 'nullnull Build';

  const hasZeroQtyItems = useMemo(
    () =>
      build.some(
        i =>
          i.node.quantity === 0 ||
          (i.node.merchandise.quantityAvailable === 0 &&
            !i.node.merchandise.product.productType.includes(
              'Extend Service Contract'
            ))
      ),
    [build]
  );

  const soldOutItems = useMemo(
    () =>
      build.filter(
        i =>
          i.node.quantity === 0 ||
          (i.node.merchandise.quantityAvailable === 0 &&
            !i.node.merchandise.product.productType.includes(
              'Extend Service Contract'
            ))
      ),
    [build]
  );

  const setCartStockNotice = useUiStore(getSetCartStockNotice);

  useEffect(() => {
    if (hasZeroQtyItems) {
      setCartStockNotice({
        buildName,
        buildLink: `/${ROUTE_BUILD}/${ROUTE_BUILD_PC}/${buildId}`,
        soldOutItems,
      });

      GTM.dataLayer({
        dataLayer: {
          event: 'cartBuildComponentOOS',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasZeroQtyItems]);

  const EDIT_LABEL = t('button_label_edit');

  return hasZeroQtyItems || hasBadBuildName ? null : (
    <li className={styles.getBaseStyle(removing)}>
      <div className={styles.baseContainer}>
        <div className={styles.imageWrapper}>
          {caseImage && (
            <Image
              className={styles.productImage}
              src={caseImage}
              id={slugify(`cart-${buildName}-image`)}
              width={150}
              height={150}
              alt={`Cart Item ${buildName || 'Name'}`}
            />
          )}
        </div>
        <div className={styles.container}>
          <Link
            href={`/${ROUTE_BUILD}/${ROUTE_BUILD_PC}/${buildId}`}
            legacyBehavior
          >
            <span className={styles.getItemNameStyle(true)}>
              <span>{buildName}</span>
            </span>
          </Link>
          {/* TODO - temporarily disable qty on builds in cart */}
          {/* TODO - refactor update item hook and API to handle mult qtys inside build */}
          {/* <div className={styles.itemQtyControls}>
            {hasZeroQtyItems || isRefurbished ? null : (
              <CartItemCounter
                key={`${buildId}`}
                id={`${buildId}`}
                defaultCount={build[0].node.quantity}
                onUpdate={updateQuantity}
                max={max}
              />
            )}
          </div> */}
        </div>
        <div className={styles.itemPriceRemove}>
          <div className={styles.uiButtonsWrapper}>
            <ButtonLink
              internalLink
              href={`/${ROUTE_BUILD}/${ROUTE_BUILD_PC}/${buildId}`}
              noChevron
              className={styles.editLabel}
            >
              {EDIT_LABEL}
            </ButtonLink>
            <span className={styles.styledDivider}> {' | '}</span>
            <button
              type="button"
              className={styles.removeButton}
              onClick={handleRemove}
              data-test-id={slugify(`${buildId}-remove-cart`)}
              disabled={loading}
              aria-label="Remove From Cart"
            >
              <IconTrash className={styles.removeButtonIcon} />
            </button>
          </div>

          <div className={styles.priceContainer}>
            <span
              className={styles.getItemPriceStyle(showDiscountPrice)}
              data-test-id={slugify(`${buildId}-cart-price`)}
            >
              {price}
            </span>
            <span
              className={showDiscountPrice ? styles.itemPrice : styles.hidden}
              id={slugify(`${buildId}-cart-discount-price`)}
            >
              {discountPrice}
            </span>
          </div>
        </div>
      </div>
      {slaItem &&
      slaItem.slaDate &&
      process.env.NEXT_PUBLIC_ENABLE_CART_ESD === 'true' ? (
        <div className="max-w-fit mb-2">
          <CartItemEsd esd={slaItem.slaDate} />
        </div>
      ) : null}
      {filteredParts && (
        <dl className={styles.partsList}>
          {Object.keys(filteredParts).map(partType => (
            <div key={partType} className={styles.partsListItem}>
              <dt className={styles.partsListLabel}>
                {t(formatPartLabel(partType))}
              </dt>
              {Array.isArray(parts[partType]) && parts[partType].length > 0 ? (
                <dd className={styles.partsListValue}>
                  {parts[partType]
                    .map(
                      p =>
                        `${p.name}${p.quantity > 1 ? ` (${p.quantity})` : ''}`
                    )
                    .join(', ')}
                </dd>
              ) : (
                <dd className={styles.partsListValue}>
                  {`${parts[partType]?.name}${
                    parts[partType]?.quantity > 1
                      ? ` (${parts[partType]?.quantity})`
                      : ''
                  }`}
                </dd>
              )}
            </div>
          ))}
        </dl>
      )}
      {region === MAXIFY_REGION_NORTHERN_AMERICA && isCartPage ? (
        <ExtendCartOffer
          item={build[0]}
          price={price}
          build={build}
          isFullPageCart
        />
      ) : (
        <ExtendCartOffer item={build[0]} price={price} build={build} />
      )}
    </li>
  );
};

export default memo(CartBuildItem);
