import { FC, memo } from 'react';
import { IconFreeShipping } from '@nzxt/react-icons';
import * as styles from './styles';

type Props = {
  esd: string;
};

const CartItemEsd: FC<Props> = ({ esd }) => (
  <div className={styles.esdWrapper}>
    <span className={styles.esdDot} />
    <IconFreeShipping className={styles.esdIcon} />
    <span className={styles.esdText}>
      Est. Ship by <span className={styles.esdDate}>{esd}</span>
    </span>
  </div>
);

export default memo(CartItemEsd);
