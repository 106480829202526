import { classnames } from '@tailwindcss-classnames';

const activeColors = classnames('text-nzxt-dark-grey-100');
const inactiveColors = classnames('text-white');

const activeAction = classnames('rotate-180');

export const chevron = (isActive: boolean): string =>
  classnames(
    'h-3',
    {
      [activeColors]: isActive,
      [activeAction]: isActive,
    },
    'stroke-current',
    'ml-1',
    'transition-transform',
    'duration-200',
    'transform',
    'hover:text-nzxt-dark-grey-100'
  );

export const headerLinkClass = (isActive: boolean): string =>
  classnames(
    {
      [activeColors]: isActive,
      [inactiveColors]: !isActive,
    },
    'font-semibold',
    'w-full',
    'text-left',
    'text-sm',
    'lg:text-xs',
    'mid-lg-xl:text-sm',
    'whitespace-nowrap',
    'hover:text-nzxt-dark-grey-100',
    'flex',
    'items-center',
    'align-middle',
    'group',
    'capitalize',
    'py-2',
    'focus:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-nzxt-purple-400',
    'focus-visible:ring-opacity-75',
    'focus-visible:ring-transparent',
    'focus-visible:ring-offset-2',
    'group-nested-link'
  );

const hiddenSubmenu = classnames(
  'opacity-0',
  'pointer-events-none',
  'invisible'
);
const visibleSubmenu = classnames('opacity-100', 'visible');

export const transformValue = {
  left: classnames(
    'lg:-translate-x-11',
    'border',
    'border-red-200',
    'border-2'
  ),
  right: classnames(
    'lg:-translate-x-1/2',
    'border',
    'border-blue-300',
    'border-2'
  ),
  none: classnames(
    'lg:-translate-x-1/3',
    'border',
    'border-green-300',
    'border-2'
  ),
};

export const getSubmenuClass = (isVisible: boolean): string =>
  classnames(
    {
      [visibleSubmenu]: isVisible,
      [hiddenSubmenu]: !isVisible,
    },
    'absolute',
    'z-10',
    '-ml-4',
    'mt-4',
    'min-w-max',
    'max-w-md',
    'lg:max-w-2xl',
    'lg:ml-0',
    'rounded-lg',
    'shadow-lg',
    'overflow-hidden'
  );

export const submenuListItem = classnames('block', 'relative');

// wrapper for submenu, extending grid styles
export const submenuWrapper = classnames('w-full', 'flex', 'justify-between');

export const itemGrid = classnames('grid', 'grid-cols-12', 'mx-auto', 'w-full');

export const gridItem = classnames('col-span-6');
export const secondaryGridItem = classnames(gridItem, 'col-start-8');

export const innerGrid = classnames(
  'relative',
  'grid',
  'gap-x-6',
  'lg:bg-nzxt-dark-grey-800',
  'px-6',
  'py-8',
  'auto-rows-min'
);

export const innerGridItem = classnames(
  'flex',
  'flex-col',
  'items-start',
  'rounded-lg'
);

export const innerInnerGrid = classnames('grid', 'w-full', 'space-y-6');

// explore
export const chevronExplore = classnames(
  'ml-2',
  'h-5',
  'w-5',
  'flex-shrink-0',
  'group-link-hover:animate-bounceRight'
);

export const explore = classnames(
  'flex',
  'items-center',
  'text-white',
  'group-link-hover',
  'hover:text-nzxt-dark-grey-100',
  'px-2',
  'justify-start'
);

export const exploreContainer = classnames(
  'flex',
  'px-5',
  'py-4',
  'justify-between',
  'items-center',
  'border-b'
);

export const exploreTextContainer = classnames(
  'px-2',
  'text-nzxt-light-grey-800'
);

export const exploreDescription = classnames('text-xs', 'text-white');
